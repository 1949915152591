import React, { useState } from "react";
import PhoneIcon from '@mui/icons-material/Phone';

const MiamiCameraCrew = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c6ff3df0-2233-429c-a523-2df6ec0cda4a");  // Use your actual access key here

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.error("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div
      className="bg-cover bg-center text-white px-4 md:px-10 py-20 font-poppins flex justify-center"
      style={{ backgroundImage: "url('/trial.webp')" }}  // Background image from public folder
    >
      <div className="w-full md:w-8/12 space-y-8">
        <div className="w-full flex flex-col lg:flex-row justify-between lg:space-x-20">
          {/* Left Side: Text Content */}
          <div className="w-full lg:w-3/5 space-y-6">
            <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold text-left mb-10">
              ATLANTA CAMERA CREW!
            </h1>

            {/* First Box: Looking for a reliable Atlanta camera crew */}
            <div className="bg-black p-6 space-y-4">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">LOOKING FOR A RELIABLE ATLANTA CAMERA CREW?</h2>
              <p className="text-sm sm:text-base lg:text-lg">
                Are you looking for a top-tier Atlanta camera crew? Search no more! At <strong>Skoolie Films</strong>, we specialize in providing exceptional camera crew services for any type of project. Whether it’s a corporate event, commercial, live broadcast, or interview, our expert content producers are equipped with the latest gear. Contact us today for a fast and reliable quote!
              </p>
              <p className="text-sm sm:text-base lg:text-lg mt-4">
              At Skoolie Films, we are often seen working out of our school bus that’s been converted into an all-in-one production vehicle. This custom-built Production Bus carries our 2 Ton G&E package and cameras, allowing us to offer a mobile, fully-equipped solution for all your production needs. With solar panels on top, we are always juiced up for whatever the production throws our way! If no-one else has ya, Skooliee Films has got you!              
              </p>
            </div>

            {/* Our Work Button */}
            <div>
              <button className="px-6 py-3 bg-gray-900 text-white font-semibold hover:bg-yellow-500 transition duration-300">
                Our Work
              </button>
            </div>

            {/* Call Now Section */}
            <div className="flex items-center space-x-4 mt-4 hover:text-yellow-400 transition duration-300">
            </div>
          </div>

          {/* Right Side: Video and Contact Form */}
          <div className="w-full lg:w-2/5 mt-8 lg:mt-0">
            <div className="bg-black p-6 space-y-6">
              {/* Video Section */}
              <div className="w-full relative" style={{ paddingBottom: "56.25%", height: 0 }}>
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/BSEvZqIUzOo"  // Updated with the actual video ID
                  title="2024 Demo Reel"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              {/* Contact Form */}
              <div className="bg-black p-8">
                <h3 className="text-xl sm:text-2xl font-semibold mb-5">QUICK CONTACT</h3>
                <form onSubmit={onSubmit} className="flex flex-col space-y-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    required
                    className="p-3 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    required
                    className="p-3 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  />
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    required
                    className="p-3 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  />
                  <select
                    name="budget"
                    required
                    className="p-3 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  >
                    <option value="">--Your Budget Range--</option>
                    <option value="low">$1,000 - $5,000</option>
                    <option value="mid">$5,000 - $10,000</option>
                    <option value="high">$10,000+</option>
                  </select>
                  <textarea
                    name="message"
                    placeholder="Message*"
                    required
                    className="p-3 bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 h-28"
                  />
                  <button
                    type="submit"
                    className="p-3 bg-yellow-500 text-white font-semibold hover:bg-yellow-400 transition duration-300"
                  >
                    Submit
                  </button>
                </form>
                <span className="text-white mt-4">{result}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiamiCameraCrew;
