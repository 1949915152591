import React from 'react';
import { InfiniteMovingCards } from './InfiniteMovingCards'; // Adjust the import path as needed

const SkoolieTestimonials = () => {
  const testimonials = [
    {
      quote: "The Skoolie team's creativity and technical expertise brought our music video to life. We love the bus!",
      name: "Sarah Johnson",
      title: "Event Coordinator, ATL Festivals"
    },
    {
      quote: "Working with Skoolie was a game-changer for our indie film. Their fully loaded bus and skilled crew made shooting in various Atlanta locations a breeze.",
      name: "Michael Chen",
      title: "Independent Film Director"
    },
    {
      quote: "The Skoolie Film Crew transformed our event coverage! Top-notch quality!",
      name: "Alicia Rodriguez",
      title: "Music Artist, Peach State Records"
    },
    {
      quote: "Skoolie's ability to bring everything need for production to our remote shooting locations was invaluable for our documentary. Highly recommended!",
      name: "David Thompson",
      title: "Marketing Director, Atlanta Tech Innovators"
    },
    {
      quote: "For our corporate video series, Skoolie Films delivered exceptional quality and professionalism. They saved us time and resources.",
      name: "Emily Patel",
      title: "Documentary Filmmaker"
    }
  ];

  return (
    <div className="w-full overflow-hidden bg-black py-10">
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center text-yellow-400 mb-8">What Our Clients Say</h2>
      <div className="relative w-full">
        <InfiniteMovingCards
          items={testimonials}
          direction="right"
          speed="slow"
          className="py-4 w-full mb-12"
        />
      </div>
    </div>
  );
};

export default SkoolieTestimonials;
