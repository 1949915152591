import React from 'react';

const ThreeBoxComponent = () => {
  const boxContent = [
    {
      header: "Over A Decade of Video Production Excellence",
      description: "Skoolie Films has been providing top-tier production crews in Atlanta for over a decade. Our team is deeply rooted in the local film industry, offering unparalleled knowledge of the city and valuable insights that are essential for successful productions in the area."
    },
    {
      header: "Mobile Studio Experience",
      description: "Experience the convenience of our renovated production bus. We bring our fully-equipped vehicle directly to your location, eliminating logistical headaches and saving you valuable time and resources. Our mobile setup ensures you have access to professional-grade equipment, no matter where your shoot takes place."
    },
    {
      header: "Comprehensive Equipment Rental",
      description: "From high-end cameras to specialized lighting rigs, Skoolie Films offers a wide range of top-quality equipment for rent. Our knowledgeable staff can assist in selecting the perfect gear for your project, and with our mobile setup, we can deliver and set up the equipment right where you need it, streamlining your production process."
    }
  ];

  return (
    <div className="bg-cover bg-center bg-no-repeat flex items-center py-10" style={{ backgroundImage: "url('/trial.webp')" }}>
      <div className="container mx-auto px-4">
        {/* Ensure that the boxes stack vertically on smaller screens and align horizontally on larger screens */}
        <div className="flex flex-col md:flex-row justify-center gap-6">
          {boxContent.map((box, index) => (
            <div key={index} className="bg-black p-6 flex flex-col justify-between w-full md:w-1/3 rounded-lg shadow-lg">
              <h2 className="font-poppins text-yellow-400 text-xl md:text-2xl lg:text-3xl font-bold mb-4">
                {box.header}
              </h2>
              <p className="font-poppins text-white text-sm md:text-base lg:text-lg">
                {box.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThreeBoxComponent;
