import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import MemeSection from './components/Memesection';
import Footer from './components/Footer';
import Divider from './components/Divider';
import MiamiCameraCrew from './components/Hero';
import TrustedClientsBanner from './components/Trusted';
import ThreeBoxComponent from './components/threebox';
import CameraCrewsComponent from './components/info';
import SkoolieTestimonials from './components/Testimonials';
import  SkooliLayoutGridDemo  from './components/SkooliGallery';
import ContactForm from './components/contact';
import OurWork from './components/videosabout';
import InstagramEmbedGallery from './components/instagram';



const App = () => {

  const logos  = [
    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",
    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png",    "/bwutterhappy.png",
    "/bwuttershocked.png",
    "/bwutterstanding.png"
  ]
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
<MiamiCameraCrew />
<TrustedClientsBanner />
      <ThreeBoxComponent />
      <SkoolieTestimonials />
      <OurWork />
      <CameraCrewsComponent />
<SkooliLayoutGridDemo />      </main>
<ContactForm />
      <Footer />
    </div>
  );
};

export default App;
