"use client";

import React from "react";
import { LayoutGrid } from "./LayoutGrid";

export function SkooliLayoutGridDemo() {
  return (
    <div
      className="h-screen py-10 w-full bg-cover bg-center mb-16" // Added 'mb-16' for margin below the layout
      style={{ backgroundImage: "url('/smoke.jpeg')" }}
    >
      <h2 className="font-bold text-yellow-400 text-5xl text-center my-4">Behind the Scenes</h2>
      <LayoutGrid cards={cards} />
    </div>
  );
}

const SkooliProject = ({ title, description }) => (
  <div>
    <p className="font-bold md:text-4xl text-xl text-white">{title}</p>
    <p className="font-normal text-base my-4 max-w-lg text-neutral-200">{description}</p>
  </div>
);

const cards = [
  {
    id: 1,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "md:col-span-2",
    thumbnail: "/skooli1.jpg",
  },
  {
    id: 2,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "col-span-1",
    thumbnail: "/skooli2.jpg",
  },
  {
    id: 3,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "col-span-1",
    thumbnail: "/skooli3.jpg",
  },
  {
    id: 4,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "col-span-1",
    thumbnail: "/skooli5.jpg",
  },
  {
    id: 5,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "md:col-span-1",
    thumbnail: "/skooli6.jpg",
  },
  {
    id: 6,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "md:col-span-2",
    thumbnail: "/skooli4.jpg",
  },
  {
    id: 7,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "md:col-span-2",
    thumbnail: "/skooli11.webp",
  },
  {
    id: 8,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "md:col-span-2",
    thumbnail: "/skooli12.webp",
  },
  {
    id: 9,
    content: <SkooliProject 
      title="Director's Chair"
      description="Where vision meets execution. Our directors bring scripts to life, one frame at a time."
    />,
    className: "col-span-2",
    thumbnail: "/skooli7.jpg",
  },
  {
    id: 10,
    content: <SkooliProject 
      title="Gear Galore"
      description="Fully loaded and ready to roll. Our gear carts pack a punch for any cinematic challenge."
    />,
    className: "col-span-1",
    thumbnail: "/skooli8.jpg",
  },
  {
    id: 11,
    content: <SkooliProject 
      title="Location Scouts"
      description="Finding the perfect backdrop. We turn Atlanta's diverse landscape into your film's canvas."
    />,
    className: "md:col-span-1",
    thumbnail: "/skooli9.jpg",
  },
  {
    id: 12,
    content: <SkooliProject 
      title="Action Ready"
      description="From pre-production to post, we're geared up to bring your vision to the screen."
    />,
    className: "col-span-2",
    thumbnail: "/skooli10.jpg",
  },
  {
    id: 13,
    content: <SkooliProject 
      title="Action Ready"
      description=""
    />,
    className: "col-span-1",
    thumbnail: "/skooli13.jpg",
  },
  {
    id: 14,
    content: <SkooliProject 
      title=""
      description=""
    />,
    className: "col-span-1",
    thumbnail: "/skooli14.jpg",
  },
];

export default SkooliLayoutGridDemo;
