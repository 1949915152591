import React from 'react';

const TrustedClientsBanner = () => {
  return (
    <div className="bg-yellow-500 text-gray-900 py-10 px-6 text-center">
      {/* Main Heading */}
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-poppins font-semibold my-4">
        TOP BRANDS KNOW OUR VIDEO PRODUCTION IS EXCEPTIONAL
      </h1>

      {/* Subheading */}
      <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl my-4 font-poppins font-semibold">
      LP Siding, Big Sipz, Tributary Homes, HAVE ALL TRUSTED US WITH THEIR CORPORATE VIDEO NEEDS. THEY KNOW SKOOLIE FILMS BASED IN ATLANTA HAS THE SKILL THAT EXCEEDS EXPECTATIONS!      </h2>
    </div>
  );
};

export default TrustedClientsBanner;
