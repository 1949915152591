import React, { useState } from 'react';

const ContactForm = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);

    formData.append("access_key", "c6ff3df0-2233-429c-a523-2df6ec0cda4a"); // Replace with your actual access key

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.error("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div
      className="relative bg-cover bg-center text-white py-20 px-10 flex justify-center"
      style={{
        backgroundImage: "url('/camera.png')", // Background image from the public folder
        backgroundSize: 'cover',
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content Container */}
      <div className="relative w-full max-w-4xl space-y-8 text-center">
        {/* Heading and Subheading */}
        <div>
          <h1 className="text-4xl font-poppins font-bold mt-2 mb-4">Contact Us</h1>
          <h2 className="text-lg font-poppins font-semibold">
            <p>contact@skooliefilms.com</p>   
            <p>bookings@skooliefilms.com</p>
          </h2>
        </div>

        {/* Contact Form */}
        <form onSubmit={onSubmit} className="relative z-10 space-y-4 bg-black bg-opacity-60 p-8 rounded-md shadow-lg">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Name*"
              required
              className="w-full p-3 bg-transparent border border-gray-400 text-white placeholder-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              required
              className="w-full p-3 bg-transparent border border-gray-400 text-white placeholder-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              required
              className="w-full p-3 bg-transparent border border-gray-400 text-white placeholder-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <select
              name="budget"
              required
              className="w-full p-3 bg-transparent border border-gray-400 text-white placeholder-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="">--Your Budget Range--</option>
              <option value="low">$1,000 - $5,000</option>
              <option value="mid">$5,000 - $10,000</option>
              <option value="high">$10,000+</option>
            </select>
          </div>
          <textarea
            name="message"
            placeholder="Message*"
            required
            className="w-full p-3 bg-transparent border border-gray-400 text-white placeholder-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-500 h-32"
          />
          <button
            type="submit"
            className="w-full py-3 bg-yellow-600 text-white font-semibold rounded hover:bg-yellow-400 transition duration-300"
          >
            Submit
          </button>
        </form>

        {/* Response Message */}
        <div className="mt-4 text-yellow-400 font-semibold">{result}</div>

        {/* Response within the hour message */}
        <div className="mt-6">
          <p className="text-lg text-yellow-500 font-semibold">RESPONSE WITHIN THE HOUR</p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
