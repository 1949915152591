import React from 'react';

const CameraCrewsComponent = () => {
  return (
    <div className="relative bg-cover bg-center min-h-screen text-white font-sans p-8 overflow-x-auto" style={{ backgroundImage: "url('/bus.png')" }}>
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-black opacity-80"></div>
      
      {/* Content */}
      <div className="relative z-10 max-w-8xl mx-auto">
        <h1 className="text-yellow-400 text-3xl sm:text-4xl md:text-6xl lg:text-7xl font-bold text-center mb-4">
          CAMERA CREWS AND INDUSTRY PROJECTS
        </h1>
        <h2 className="text-white text-base sm:text-lg md:text-xl text-center mb-8">
          NEED TO HIRE AN ATLANTA CAMERA CREW? HERE'S WHAT YOU NEED TO KNOW
        </h2>

        <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 md:space-x-16">
          {/* Camera Gear Section */}
          <div className="w-full md:w-80">
            <h3 className="text-yellow-400 text-xl sm:text-2xl font-bold mb-4">CAMERA GEAR</h3>
            <p className="mb-4 font-semibold text-sm sm:text-base">FILMING IN 1080P, 4K- 6K AND 8K 12K, NTSC AND PAL</p>
            <ul className="list-none font-semibold text-sm sm:text-base">
              {[
                "Sony FX9",
                "Sony FX6",
                "RED Komodo",
                "DZO Pictor Zoom Lenses",
                "Tripods",
                "DJI Mavic Mini 3",
                "Camera Cart",
                "Battery Cart",
                "21.5 inch Monitor",
                "Handheld Monitor",
                "Hollyland C1 Pro Headsets",
                "C-Stands",
                "Combo Stands",
                "12x12 Frame and Rags",
                "6x6 Frame and Rags",
                "4x4x and 2x3s",
                "Sandbags",
                "Apple Boxes",
                "Dana Dolly",
                "Various Grippage, clamps, etc.",
                "CTO",
                "CTB",
                "Various Colored Gels",
                "2 Ton G&E Package"
              ].map((item, index) => (
                <li key={index} className="mb-2">— {item}</li>
              ))}
            </ul>
          </div>

          {/* Camera Crew Section */}
          <div className="w-full md:w-80">
            <h3 className="text-yellow-400 text-xl sm:text-2xl font-bold mb-4">ATLANTA CAMERA CREW</h3>
            <p className="mb-4 font-semibold text-sm sm:text-base">PROFESSIONAL CAMERA CREWS FOR HIRE</p>
            <ul className="list-none font-semibold text-sm sm:text-base">
              {[
                "Director of photography (DP or DOP)",
                "1st Assistant Camera (1st AC)",
                "2nd Assistant Camera (2nd AC)",
                "Grip, Gaffer, Electric, Utility",
                "DIT, HMU, PA, Set Photographer",
                "Production Sound Mixer, Boom Operator",
                "Art Director, Prop master, Location Scout",
                "Producer, Production Coordinator"
              ].map((item, index) => (
                <li key={index} className="mb-2">— {item}</li>
              ))}
            </ul>
          </div>

          {/* Projects We Produce Section */}
          <div className="w-full md:w-80">
            <h3 className="text-yellow-400 text-xl sm:text-2xl font-bold mb-4">PROJECTS WE PRODUCE</h3>
            <ul className="list-none font-semibold text-sm sm:text-base">
              {[
                "Event Coverage", "Teleprompter Services",
                "Live Stream/Webcasting", "Testimonials/Interviews",
                "Restaurant/Food Service", "Medical/Healthcare",
                "TV Commercial Production", "Promotional & Training Videos",
                "Video Marketing", "Corporate Video Production",
                "Industrial Videos", "Conference/Trade Show",
                "Law Firm/Legal", "Political Campaign Ads",
                "Business Video Production", "School/University/Education",
                "Church Video Production", "Animation/Explainer Videos",
                "Corporate Videography", "Health and Beauty Video Production",
                "Music Video Production", "Crowdfunding Video Production",
                "eCommerce Video Production", "EPK Services",
                "Infomercial Video Production", "Multimedia Production",
                "Non-Profit Video Production", "Concert Video Production"
              ].map((item, index) => (
                <li key={index} className="mb-2">— {item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraCrewsComponent;
